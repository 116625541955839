import React from "react"
import HeaderTwo from "../common/header/HeaderTwo"
import FooterTwo from "../common/footer/FooterTwo"
import Copyright from "../common/footer/Copyright"
import SectionTitle from "../elements/sectionTitle/SectionTitle"
import BrandFour from "../elements/brand/BrandFour"
import Separator from "../elements/separator/Separator"
;<HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />
const Partners = () => {
  return (
    <>
      <div className="main-content">
        {/* Start Brand Area  */}
        <div className="rwt-brand-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=" Partners we work with "
                  description=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--40">
                <BrandFour brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        <Separator />
      </div>
    </>
  )
}
export default Partners
